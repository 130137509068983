@import '../../styles/colors';

.addSceneTabContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.title {
	color: #4d4f5c;
	font-size: 30px;
	font-family: 'Ubuntu', sans-serif;
	font-weight: bold;
	margin-bottom: 35px;
}

.sceneTypeTitle {
	text-align: center;
	color: #b8d5ef;
	font-size: 15px;
	font-weight: bold;
	margin-bottom: 24px;
	margin-top: 9px;
}

.selectedTemplate {
	border: 3px solid $primary-color;
}

.image {
	width: 250px;
	height: 140px;
	cursor: pointer;
}
