@import '../../styles/colors';

$height: 17px;

.switch {
	position: relative;
	display: inline-block;
	width: calc(#{$height} * 2);
	height: $height;
	margin: 0px 8px;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #e7eef4;
	transition: 0.4s;
}

.slider:before {
	position: absolute;
	content: '';
	height: $height;
	width: $height;
	background-color: $primary-color;
	transition: 0.4s;
}

input:checked + .slider {
	background-color: #e7eef4;
}

input:focus + .slider {
	box-shadow: 0 0 1px #e7eef4;
}

input:checked + .slider:before {
	transform: translateX($height);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
