@import '../../styles/colors';
@import '../../styles/placeholders';

.inputGroup {
	padding: 15px 0px;
	display: flex;
	flex-direction: column;
}

.inputToggle {
	margin-bottom: 10px;
	display: flex;
	position: relative;
	align-items: center;

	label {
		margin-bottom: 0px;
	}
}

.label {
	font-size: 12px;
	margin-bottom: 10px;
	color: $text-color;
}

.imageRow {
	display: flex;
}

.condition {
	color: $light-color;
	align-self: center;
	font-size: 12px;
	margin-left: 10px;
	max-width: 120px;
}
