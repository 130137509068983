@import '../../styles/colors';
@import '../../styles/z_indexes';
@import '../../styles/placeholders';
@import '../../styles/shared_variables';

.canvas {
	width: calc(100% - #{$global-sidebar-width-desktop});
	overflow: hidden;
	position: relative;
	z-index: $z-canvas;

	&.isCreating {
		div[tabindex='0'] {
			cursor: copy;
		}
	}
}

.projectTitle {
	position: absolute;
	z-index: $z-title;
	color: $text-color;
	font-size: 30px;
	top: 40px;
	left: 50px;
	cursor: pointer;
	@extend %global-transition;

	&:hover,
	&:focus,
	&:active {
		color: $primary-color;

		svg {
			path {
				@extend %global-transition;
				fill: $primary-color;
			}
		}
	}
}

.ico {
	width: 14px;
	height: 22px;
	margin-right: 20px;
}

.plusButton {
	position: absolute;
	z-index: $z-title;
	bottom: 30px;
	right: 390px;
	cursor: pointer;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			&:first-of-type {
				fill: darken($primary-color, 10%);
			}
		}
	}
}

.active {
	path {
		&:first-of-type {
			fill: darken($primary-color, 15%);
		}
	}
}

.scene {
	position: absolute;
	width: 350px;
	height: 200px;

	div:first-of-type > div:nth-of-type(2) {
		display: none;
	}
}

.deleteButton {
	display: none;
	position: absolute;
	top: -14px;
	right: -12px;
	z-index: 99;
	background: #f46e69;
	color: white;
	height: 20px;
	width: 20px;
	font-size: 10px;
	font-weight: bold;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.copyButton {
	display: none;
	position: absolute;
	top: -14px;
	right: 12px;
	z-index: 99;
	background: #585968;
	color: white;
	height: 20px;
	width: 20px;
	font-size: 10px;
	font-weight: bold;
	border-radius: 10px;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.copyIcon {
	width: 10px;
	height: 10px;
}

.selected {
	border: 3px solid $primary-color;
	.deleteButton {
		display: flex;
	}
	.copyButton {
		display: flex;
	}
}

.playButton {
	position: absolute;
	z-index: $z-title;
	top: 40px;
	right: 390px;
	width: 55px;
	height: 55px;
	cursor: pointer;

	&:hover,
	&:focus {
		path {
			@extend %global-transition;
			&:first-of-type {
				fill: darken($primary-color, 10%);
			}
		}
	}
}

.firstSceneBadge {
	position: absolute;
	z-index: $z-title;
	bottom: 80px;
	right: 420px;
	width: 420px;
}

.arrowBadge {
	position: absolute;
	z-index: $z-title;
	bottom: 80px;
	right: 380px;
	width: 100px;
}

.sceneClickBadge {
	position: absolute;
	z-index: $z-title;
	bottom: 30px;
	right: 480px;
	width: 460px;
}
